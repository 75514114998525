<template>
  <div class="box-content">
    <div class="spinner" v-if="loading">
      <div class="line1"></div>
    </div>
    <div v-else>
      <h2>Modifier un opérateur</h2>
      <div class="parent_box_content">
        <text-input
          v-model="form.code"
          label="Code de l'opérateur"
          :errors="errors.code"
        />
        <text-input
          v-model="form.handlerclass"
          label="Methode"
          :errors="errors.handlerclass"
        />
        <text-input
          v-model="form.email"
          label="Email"
          type="email"
          :errors="errors.email"
        />
        <text-input
          v-model="form.phone"
          label="Téléphone"
          type="number"
          :errors="errors.phone"
        />
      </div>
      <div class="parent_box_second">
        <text-input
          v-model="form.ifu"
          label="Numéro d'identification"
          :errors="errors.ifu"
        />
        <text-input
          v-model="form.description"
          label="Description"
          :errors="errors.description"
        />
      </div>
      <hr />
      <div class="action_btn">
        <button
          @click="addProperties"
          class="button is-responsive is-outlined is-small"
        >
          Ajouter une propriété
        </button>
        <button
          v-if="form.properties.length === 0"
          @click="updateOperator"
          :class="
            edit
              ? 'button is-responsive is-outlined is-small is-loading'
              : 'button is-responsive is-outlined is-small'
          "
        >
          Enrégistrer
        </button>
      </div>
      <div v-if="form.properties.length > 0">
        <div
          v-for="(prop, key) in form.properties"
          :key="key"
          class="display_properties"
        >
          <text-input
            v-model="prop.name"
            label="Nom"
            :errors="errors['properties.' + key + '.name']"
          />
          <text-input
            v-model="prop.value"
            label="Valeur"
            :errors="errors['properties.' + key + '.value']"
          />
          <text-input
            v-model="prop.description"
            label="Description"
            :errors="errors['properties.' + key + '.handlerclass']"
          />
          <button
            @click="removeProperties(key)"
            class="button is-small is-primary hi-self"
          >
            <i class="fa fa-remove"></i>
          </button>
        </div>
        <div style="padding-top: 20px">
          <button
            @click="updateOperator"
            :class="
              edit
                ? 'button is-responsive is-outlined is-small is-loading'
                : 'button is-responsive is-outlined is-small'
            "
          >
            Enrégistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import TextInput from "@/components/TextInput";
export default {
  name: "editOperator",
  components: {
    TextInput,
  },
  data: () => ({
    loading: false,
    edit: false,
    errors: [],
    form: {
      id: "",
      code: "",
      handlerclass: "",
      email: "",
      phone: "",
      ifu: "",
      description: "",
      param1: "",
      param2: "",
      param3: "",
      properties: [],
    },
  }),
  computed: {
    ...mapGetters({
      bank: `${TYPES.modules.BANKS}/${TYPES.getters.GET_BANK}`,
    }),
  },
  mounted() {
    this.getBnak();
  },
  methods: {
    addProperties() {
      this.form.properties.push({
        name: "",
        value: "",
        description: "",
      });
    },

    removeProperties(key) {
      this.form.properties.splice(key, 1);
    },

    getBnak() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.BANKS + "/" + TYPES.actions.DETAILS_BANK}`,
          this.$route.params.id
        )
        .then((res) => {
          if (res) {
            this.form = this.bank;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    updateOperator() {
      this.edit = true;
      this.$store
        .dispatch(
          `${TYPES.modules.BANKS + "/" + TYPES.actions.UPDATE_BANK}`,
          this.form
        )
        .then((res) => {
          this.edit = false;
          this.$toast.success(`L'opérateur a été mis à jour`);
        })
        .catch((error) => {
          this.edit = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.parent_box_content {
  padding-top: 20px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.parent_box_second {
  padding-top: 10px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 2fr;
}
.action_btn {
  display: flex;
  justify-content: space-between;
}
.display_properties {
  display: grid;
  grid-gap: 10px;
  padding-top: 20px;
  grid-template-columns: 1fr 1fr 2fr 20px;
  .hi-self {
    align-self: end;
  }
}
</style>